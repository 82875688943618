import * as React from 'react'
import { Layout } from '../components/layout'

import * as styles from './graphics.module.scss'

interface Color {
  pantone: string
  cmyk: string
  rgb: string
  hex: string
  text?: string
}
export default class extends React.Component {
  render() {
    const assets = [
      {
        name: 'Favicon',
        image: require('@kadadev/assets/favicon.png'),
        imageUrl: '@kadadev/assets/favicon.png',
      },
      {
        name: 'Letterhead with background',
        image: require('@kadadev/assets/letterhead_background.svg'),
        imageUrl: '@kadadev/assets/letterhead_background.svg',
      },
      {
        name: 'Letterhead',
        image: require('@kadadev/assets/letterhead.svg'),
        imageUrl: '@kadadev/assets/letterhead.svg',
      },
      {
        name: 'Slime',
        image: require('@kadadev/assets/slime.svg'),
        imageUrl: '@kadadev/assets/slime.svg',
      },
      {
        name: 'Logo without margin',
        desc: 'Good for app icons and similar',
        image: require('@kadadev/assets/logo_square_no_margin.svg'),
        imageUrl: '@kadadev/assets/logo_square_no_margin.svg',
      },
      {
        name: 'Square logo',
        image: require('@kadadev/assets/logo_square.svg'),
        imageUrl: '@kadadev/assets/logo_square.svg',
      },
      {
        name: 'Logo with text',
        image: require('@kadadev/assets/logo.svg'),
        imageUrl: '@kadadev/assets/logo.svg',
      },
    ]

    const colors: Color[] = [
      {
        pantone: '4625 C',
        cmyk: '24, 75, 78, 66',
        rgb: '74, 47, 35',
        hex: '#4F2C1D',
      },
      {
        pantone: '1585 C',
        cmyk: '0, 68, 90, 0',
        rgb: '232, 107, 31',
        hex: '#FF6A13',
      },
      {
        pantone: '113 C',
        cmyk: '3, 10, 77, 0',
        rgb: '245, 223, 92',
        hex: '#FAE053',
        text: 'black',
      },
    ]
    return (
      <Layout>
        <h1>Graphical assets</h1>
        <section>
          <h2>How to use</h2>
          <p>
            There is an NPM package that contains the colors and all the assets
            at{' '}
            <a href="https://www.npmjs.com/package/@kadadev/assets">
              @kadadev/assets
            </a>
            .
          </p>
          <code>yarn add @kadadev/assets</code>
        </section>
        <section>
          <h2>Typeface</h2>
          <p>
            Kåda uses{' '}
            <a href="https://fonts.google.com/specimen/Quicksand">Quicksand</a>{' '}
            with a fallback to system fonts. <b>Headings</b> and other{' '}
            <em>emphasised text</em> is bold
          </p>
        </section>
        <section>
          <h2>Colors</h2>
          {colors.map(c => (
            <Color key={c.hex} color={c} />
          ))}
        </section>
        <div style={{ display: 'flex', flexWrap: 'wrap', background: '#eee' }}>
          {assets.map(({ name, image, desc, imageUrl }, i) => (
            <section
              key={i}
              style={{ textAlign: 'center', flexBasis: '400px' }}
            >
              <h3>{name}</h3>
              {desc && <p>{desc}</p>}
              <code>{imageUrl}</code>
              <img src={image} style={{ background: 'white' }} />
            </section>
          ))}
        </div>
      </Layout>
    )
  }
}

export const Color: React.SFC<{ color: Color }> = ({ color }) => (
  <dl
    className={styles.color}
    style={{ backgroundColor: color.hex, color: color.text }}
  >
    <dt>Pantone</dt>
    <dd>{color.pantone}</dd>
    <dt>CMYK</dt>
    <dd>{color.cmyk}</dd>
    <dt>RGB</dt>
    <dd>{color.rgb}</dd>
    <dt>HEX</dt>
    <dd>{color.hex}</dd>
  </dl>
)
